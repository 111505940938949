import Layout from '../components/Layout'

const NotFound = () => (
  <Layout withSideNav={false}>
    <div className="flex flex-1 items-center justify-center">
      <p className="text-secondary">This page doesn't exist.</p>
    </div>
  </Layout>
)

export default NotFound
